export default {
  regType: "MSGROUP",
  groupName: "",
  groupCode: "",
  schoolId: "",
  otherSchoolName: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  lunchId: "",
  tShirtSizeId: ""
}