<template>
  <b-container class="gr-border px-3">
    <div class="text-end">
      <router-link :to="{name: 'ms-group-members-pending-list'}"><button type="button" class="btn-close" aria-label="Close"></button></router-link>
    </div>
    <div class="text-center gr-bak">
      <h1 class="dk-blue upper"><strong>Middle School Group Pending Removal Member</strong></h1>
    </div>
    <div class="form-heading dark-bak">
      <h3>1. Student Information</h3>
    </div>
    <div>
      <table class="student-info">
        <tr>
          <td><label>First Name</label></td>
          <td>{{member.firstName}}</td>
        </tr>
        <tr>
          <td><label>Last Name</label></td>
          <td>{{member.lastName}}</td>
        </tr>
        <tr>
          <td><label>Email</label></td>
          <td>{{member.email}}</td>
        </tr>
      </table>
    </div>
    <div class="form-heading dark-bak">
      <h3>2. Please choose an option</h3>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="option" id="option-0" v-model="option" value="0">
      <label class="form-check-label" for="option-0">Remove the user from the group and change to individual registration</label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="option" id="option-1" v-model="option" value="1">
      <label class="form-check-label" for="option-1">Cancel the registration completely (change to status of Inactive)</label>
    </div>
    <div class="form-check">
      <input class="form-check-input" type="radio" name="option" id="option-2" v-model="option" value="2">
      <label class="form-check-label" for="option-2">Move the user to a new group</label>
    </div>
    <div v-if="option==2" class="col-md-6 mt-3">
      <label for="group-name"><span class="text-danger">*</span>Available Groups</label>
      <select id="group-name" name="group-name" class="form-select" v-model="newGroupId">
        <option value="">-Select Option-</option>
        <option v-for="group in groups" v-bind:value="group.id" v-bind:key="group.id">
          {{ group.groupName }}
        </option>
      </select>
    </div>

    <div class="row my-4">
      <div class="col-md-4">
        <button class="btn btn-yellow" v-on:click="update">SAVE</button>
      </div>
      <div v-if="isBusy" class="col-md-4 align-self-center text-center">
        <b-spinner></b-spinner>
      </div>
      <div v-else class="col-md-4 mt-2">
        <p v-if="errorMsg"><strong><span class="text-danger">{{errorMsg}}</span></strong></p>
      </div>
      <div class="col-md-4">
        <router-link :to="{name: 'ms-group-members-pending-list'}"><button class="btn btn-yellow">CANCEL</button></router-link>
      </div>
    </div>
  </b-container>
</template>

<script>
  import dataService from '../services/data.service';

  export default {
    props: ['member'],
    data: function() {
      return {
        isBusy: false,
        errorMsg: '',
        groups: [],
        option: '',
        newGroupId: ''
      };
    },
    async mounted (){
      this.isBusy = true;
      try {
        if(this.$route.params.member.id) {
          let groupList = await dataService.getMSGroups();
          this.groups = groupList.data.data;
        } else {
          this.$router.push({ name: 'ms-group-members-pending-list' });
        }

        this.isBusy = false;

      } catch (error) {
        this.isBusy = false;
        console.log(error);
      }
    },
    methods: {
      update: async function (event) {
        event.preventDefault();
        this.errorMsg = '';
        this.isBusy = true;

        if (!this.option) {
          this.errorMsg = 'Please select an option';
          this.isBusy = false;
          return;
        }

        if(this.option == 2 && !this.newGroupId){
          this.errorMsg = 'Please select new group';
          this.isBusy = false;
          return;
        }

        try {
          if(this.option == 1) {
            await dataService.updateRegistrationStatus({id: this.member.id, email: this.member.email, status: 'inactive'});
          } else {
            if(this.option == 0) {
              this.newGroupId = '';
            }
            await dataService.changeGroup({id: this.member.id, email: this.member.email, groupId: this.newGroupId});
          }
          this.$router.push({ name: 'ms-group-members-pending-list', params: {refreshTable: true}});
        } catch (error) {
          this.isBusy = false;
          this.errorMsg = 'An error occurred. Please try again later.';
          console.log(error);
        }
      }

    }
  }

</script>

<style scoped>
  .student-info td label{
    padding-right: 10px;
  }
</style>