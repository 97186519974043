<template>
  <div>
    <div class="text-banner text-center">
      <h2><strong>Welcome to STEM Day Admin Tool</strong></h2>
    </div>
  </div>
</template>

<script>
  export default {}
</script>