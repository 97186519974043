<template>
  <div>
    <div class="text-banner text-center">
      <h2><strong>Middle School Group Pending Removal Members</strong></h2>
    </div>
    <b-container fluid >
      <b-row align-v="center" class="my-1">
        <b-col sm="4" md="4">
          <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            placeholder="Type to Search"
            size="sm"
          ></b-form-input>
        </b-col>
        <b-col sm="2" md="2" class="text-md-end">
          <label for="per-page-select">Rows per page</label>
        </b-col>
        <b-col sm="1" md="1">
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
          ></b-form-select>
        </b-col>
        <b-col sm="5" md="5">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </b-col>
      </b-row>
      <div class="h-scrollable-container">
        <b-table
          ref="tableList"
          striped
          bordered
          hover
          small
          :no-provider-sorting="true"
          :no-provider-paging="true"
          :no-provider-filtering="true"
          :busy.sync="isBusy"
          :items="getRegList"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          @filtered="onFiltered">
          <template #cell(actions)="row">
            <router-link
              :to="{name: 'ms-group-members-pending-edit', params: {member: row.item}}"
              v-slot="{navigate}"
              custom
            >
              <b-button size="sm" @click="navigate">Edit</b-button>
            </router-link>
          </template>
        </b-table>
      </div>
    </b-container>
    <div v-if="showModal" class="modal-edit">
      <div class="modal-edit-content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
  import dataService from '../services/data.service';

  export default {
    watch: {
      $route: {
        immediate: true,
        handler: function(route) {
          if(route.params.refreshTable) {
            this.$refs.tableList.refresh();
          }
          this.showModal = route.meta && route.meta.showModal;
        }
      }
    },
    data () {
      return {
        showModal: false,
        isBusy: false,
        fields: [
          { key: 'actions', label: ''},
          { key: 'firstName', sortable: true },
          { key: 'lastName', sortable: true },
          { key: 'email', sortable: true },
          { key: 'statusMessage' },
        ],
        totalRows: 1,
        currentPage: 1,
        perPage: 15,
        pageOptions: [15, 30, 50],
        filter: null
      }
    },
    methods: {
      getRegList: async function() {
        this.isBusy = true
        try {
          let response = await dataService.getMSGroupMembersPending();
          this.isBusy = false
          this.totalRows = response.data.data.length
          return response.data.data
        } catch (error) {
          this.isBusy = false
          console.log(error)
          return []
        }
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      }
    }
  }
</script>