<template>
  <div class="gr-border container px-3">
    <div class="text-end">
      <router-link :to="{name: 'ms-group-reg-list'}"><button type="button" class="btn-close" aria-label="Close"></button></router-link>
    </div>
    <ValidationObserver ref="form" v-slot="{ valid }">
      <form>
        <fieldset :disabled="isBusy">
          <div class="row">
            <div class="text-center gr-bak col-sm-12">
              <!---
              <h1 class="dk-blue upper" v-if="!isEdit"><strong>Middle School STEM Career Day {{$stemYear}}<br/>Group Registration</strong></h1>
              --->
              <h1 class="dk-blue upper" v-if="isEdit"><strong>Middle School STEM Career Day {{$stemYear}}<br/>Edit Group Registration</strong></h1>
            </div>
          </div>
          <br/>
          <div class="row">
            <div class="col-12">
              <div class="form-heading dark-bak">
                <h3>1. Create a Group</h3>
              </div>
            </div>
            <div class="col-12"><p>Please be sure to communicate group name and code to those joining your group. Students will need to select group name and enter group code during individual registration.</p></div>
            <div class="col-md-6">
              <ValidationProvider vid="groupName" rules="required" v-slot="{ classes, errors }">
                <div class="form-group">
                  <label for="group-name"><span class="text-danger">*</span>Group Name</label>
                  <a class="btn-popover px-2" tabindex="0" v-b-popover.click.blur.top="'Individuals will have the ability to select this group name and verify the group code during the individual registration process. Please note that group codes are case sensitive.'">
                    <b-icon-question-circle-fill variant="info"></b-icon-question-circle-fill>
                  </a>
                  <input type="text" name="group-name" id="group-name" class="form-control" :class="classes" v-model="formData.groupName">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{ classes, errors }">
                <div class="form-group">
                  <label for="group-code"><span class="text-danger">*</span>Create Group Code</label>
                  <a class="btn-popover px-2" tabindex="0" v-b-popover.click.blur.top="'Code should be short and easy to remember. Individuals can join this group by selecting group name and entering group code during the individual registration process. Please note that group codes are case sensitive.'">
                    <b-icon-question-circle-fill variant="info"></b-icon-question-circle-fill>
                  </a>
                  <input type="text" name="group-code" id="group-code" class="form-control" :class="classes" v-model="formData.groupCode">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{ classes, errors }">
                <div class="form-group">
                  <label for="ms-school-selector"><span class="text-danger">*</span>School</label>
                  <select id="ms-school-selector" name="ms-school-selector" class="form-select" :class="classes" v-model="formData.schoolId">
                    <option value="">-Select Option-</option>
                    <option value="other">My school is not listed here. I will enter school in next field.</option>
                    <option v-for="school in schools" v-bind:value="school.id" v-bind:key="school.id">
                      {{ school.title }}
                    </option>
                  </select>
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider :rules="{required: formData.schoolId=='other' }" v-slot="{ classes, errors }">
                <div class="form-group">
                  <label for="school-other"><span v-if="formData.schoolId=='other'" class="text-danger">*</span>School (If not in previous list)</label>
                  <input type="text" name="school-other" id="school-other" class="form-control" :class="classes" v-model="formData.otherSchoolName" :disabled="formData.schoolId !== 'other'">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-heading dark-bak">
                <h3>2. Group Leader Contact Information</h3>
              </div>
            </div>
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{ classes, errors }">
                <div class="form-group">
                  <label for="first-name"><span class="text-danger">*</span>First Name</label>
                  <input type="text" name="first-name" id="first-name" class="form-control" :class="classes" v-model="formData.firstName">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{ classes, errors }">
                <div class="form-group">
                  <label for="last-name"><span class="text-danger">*</span>Last Name</label>
                  <input type="text" name="last-name" id="last-name" class="form-control" :class="classes" v-model="formData.lastName">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider vid="email" rules="required|email" v-slot="{ classes, errors }">
                <div class="form-group">
                  <label for="email"><span class="text-danger">*</span>Email Address</label>
                  <a class="btn-popover px-2" tabindex="0" v-b-popover.click.blur.top="'Your password will be sent to this email in order to log in and update your registration.'">
                    <b-icon-question-circle-fill variant="info"></b-icon-question-circle-fill>
                  </a>
                  <input type="email" name="email" id="email" class="form-control" :class="classes" v-model="formData.email" :disabled="isEdit">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider :rules="{ required: true, email: true, confirmEmail: '@email'}" v-slot="{ classes, errors }" v-if="!isEdit">
                <div class="form-group">
                  <label for="email_confirm"><span class="text-danger">*</span>Confirm Email Address</label>
                  <input type="email" name="email_confirm" id="email_confirm" v-model="emailConfirm" class="form-control" :class="classes">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{ classes, errors }">
                <div class="form-group">
                  <label for="phone"><span class="text-danger">*</span>Phone Number</label>
                  <input type="text" name="phone" id="phone" v-model="formData.phone" class="form-control" :class="classes">
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{ classes, errors }">
                <div class="form-group">
                  <label for="lunch"><span class="text-danger">*</span>Lunch</label>
                  <select id="lunch" name="lunch" class="form-select" v-model="formData.lunchId" :class="classes">
                    <option value="">-Select Option-</option>
                    <option v-for="lunchType in lunchTypes" v-bind:value="lunchType.id" v-bind:key="lunchType.id">
                      {{ lunchType.title }}
                    </option>
                  </select>
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider rules="required" v-slot="{ classes, errors }">
                <div class="form-group">
                  <label><span class="text-danger">*</span>T-Shirt Size</label>
                  <select class="form-control" :class="classes" v-model="formData.tShirtSizeId">
                    <option value="" >-Select Option-</option>
                    <option v-for="tShirtSize in tShirtSizes" v-bind:value="tShirtSize.id" v-bind:key="tShirtSize.id">
                      {{ tShirtSize.title }}
                    </option>
                  </select>
                  <div class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </div>
          </div>
          <div class="row" v-if="isEdit">
            <div class="col-12">
              <div class="form-heading dark-bak">
                <h3>3. Group Members</h3>
              </div>
            </div>
            <div class="col-12"><p>Below are the individuals who have registered to join your group. Here you can view the status of each individual registration and request to remove an individual from the group.</p></div>
            <div class="col-12 table">
              <table class="table-responsive">
                <tr v-for="(item, index) in groupMembers" v-bind:key="index">
                  <td>{{item.firstName}} {{item.lastName}}</td>
                  <td v-if="item.status=='pending-removal'" class="px-5">
                    Pending Removal
                  </td>
                  <td v-else class="text-center px-3">
                    <a v-b-modal="'removeItemModal-'+index">
                      <b-icon icon="trash"></b-icon>
                    </a>
                    <b-modal :id="'removeItemModal-'+index" title="Remove Individual from Group" ok-title="YES" :ok-disabled="!item.statusMessage" cancel-title="NO" @ok="removeGroupMember(item);" centered no-fade>
                      <template #modal-header="{ close }">
                        <button class="btn-close btn-close-white" @click="close()"></button>
                      </template>
                      <p><span class="text-danger">*</span>Please provide a reason for requesting to remove from this group so we can contact this student and update their registration accordingly:</p>
                      <textarea :id="'reason-'+index" class="form-control" rows="3" v-model="item.statusMessage"></textarea>
                    </b-modal>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="row my-4">
            <div class="col-md-4">
              <button class="btn btn-yellow" v-on:click="edit" v-if="isEdit">UPDATE GROUP</button>
            </div>
            <div v-if="isBusy" class="col-md-4 align-self-center text-center">
              <b-spinner></b-spinner>
            </div>
            <div v-else class="col-md-4 mt-2">
              <p v-if="!valid && showErrorMsg"><strong><span class="text-danger">Please check all required fields</span></strong></p>
            </div>
            <div class="col-md-4">
              <router-link :to="{name: 'ms-group-reg-list'}"><button class="btn btn-yellow">CANCEL</button></router-link>
            </div>
          </div>
        </fieldset>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
  import MSGroupRegistration from '../models/ms-group-registration';
  import dataService from '../services/data.service';
  import { lunchTypes } from '../data/lunch-types';
  import { tShirtSizes } from '../data/tshirt-sizes';

  export default {
    props: ['regId','email','ageVerified'],
    data: function() {
      return {
        isBusy: false,
        isEdit: false,
        showErrorMsg: false,
        schools: [],
        lunchTypes: lunchTypes,
        tShirtSizes: tShirtSizes,
        formData: JSON.parse(JSON.stringify(MSGroupRegistration)), //clone to avoid caching, doesn't work for complex types (e.g. Date)
        emailConfirm: "",
        groupMembers: []
      };
    },
    async mounted (){
      this.isBusy = true;
      try {
        if(this.$route.params.regId) {
          this.isEdit = true;
          let regData = await dataService.getRegistrationData(this.$route.params.regId, this.$route.params.email);
          this.formData = regData.data.data;
          let groupMembersData = await dataService.getMSGroupMembers(this.$route.params.regId);
          this.groupMembers = groupMembersData.data.data;
        } else {
          this.$router.push({ name: 'ms-group-reg-list' });
        }

        let schoolList = await dataService.getCatalogItemsByCategory("MIDSCH");
        this.schools = schoolList.data.data;

        this.isBusy = false;

      } catch (error) {
        this.isBusy = false;
        console.log(error);
      }
    },
    methods: {
      edit: async function (event) {
        event.preventDefault();
        this.isBusy = true;

        let isFormValid = await this.$refs.form.validate();
        if(!isFormValid){
          this.showErrorMsg = true;
          this.isBusy = false;
          return;
        }

        this.clearHiddenFields();

        try {
          let response = await dataService.updateMSGroupReg(this.$route.params.regId, this.formData);
          if (response.data.code == 0) {
            this.$router.push({ name: 'ms-group-reg-list', params: {refreshTable: true}});
          } else if (response.data.code == -3) {
            this.$refs.form.setErrors({groupName: [response.data.message]});
          } else {
            console.log(response.data.message);
          }
          this.isBusy = false;
        } catch (error) {
          this.isBusy = false;
          console.log(error);
        }
      },

      clearHiddenFields: function () {
        if (this.formData.schoolId != 'other') {
          this.formData.otherSchoolName = "";
        }
      },

      removeGroupMember: async function (item) {
        try {
          this.isBusy = true;
          item.status = 'pending-removal'
          await dataService.updateRegistrationStatus(item);
          this.isBusy = false;
        } catch (error) {
          this.isBusy = false;
        }
      }
    }
  }

</script>